<template>
  <div class="menu-container" :class="containerClass">
    <b-menu class="top-menu">
      <b-menu-list>
        <b-menu-item :active="isActive('dashboard')" tag="router-link" to="/admin/dashboard">
          <template #label>
            <material-icon icon="dashboard"></material-icon>
            <span class="menu-label-text">Admin Dashboard</span>
          </template>
        </b-menu-item>
        <b-menu-item :active="isActive('admin:global')" tag="router-link" to="/admin/reasons">
          <template #label>
          <material-icon icon="store"></material-icon>
          <span class="menu-label-text">Global reasons</span>
          </template>
        </b-menu-item>
        <b-menu-item :active="isActive('retailers')" tag="router-link" to="/retailers">
          <template #label>
          <material-icon icon="store"></material-icon>
          <span class="menu-label-text">Retailers</span>
          </template>
        </b-menu-item>
        <b-menu-item :active="isActive('sessions')"
                     tag="router-link"
                     to="/sessions">
           <template #label>
             <material-icon icon="forum"></material-icon>
             <span class="menu-label-text">Nibbles</span>
           </template>
         </b-menu-item>
        <b-menu-item :expanded="isExperimentsExpanded()"
                     tag="router-link"
                     to="/admin/experiments">
          <template #label>
            <material-icon icon="calculate"></material-icon>
            <span class="menu-label-text">Experiments</span>
            <material-icon icon="arrow_drop_down"></material-icon>
          </template>
          <b-menu-item
                       :active="isActive('experiments')"
                       tag="router-link"
                       to="/admin/experiments">
             <template #label>
               <material-icon icon="calculate"></material-icon>
               <span class="menu-label-text">Experiments</span>
             </template>
          </b-menu-item>
          <b-menu-item
                       :active="isActive('experiments-archive')"
                       tag="router-link"
                       to="/admin/experiments-archive">
             <template #label>
               <material-icon icon="inventory_2"></material-icon>
               <span class="menu-label-text">Archive</span>
             </template>
          </b-menu-item>
        </b-menu-item>
       <b-menu-item :active="isActive('admin:algorithm')"
                    tag="router-link"
                    to="/admin/algorithm">
          <template #label>
            <material-icon icon="calculate"></material-icon>
            <span class="menu-label-text">Algorithm Settings</span>
          </template>
        </b-menu-item>
        <b-menu-item :active="isActive('admin:search')" tag="router-link" to="/admin/search">
          <template #label>
            <material-icon icon="search"></material-icon>
            <span class="menu-label-text">Message Search</span>
          </template>
        </b-menu-item>
        <b-menu-item :active="isActive('admin:theme')"
                      tag="router-link"
                      to="/admin/themes">
          <template #label>
            <material-icon icon="mode_edit"></material-icon>
            <span class="menu-label-text">Themes</span>
          </template>
        </b-menu-item>
        <b-menu-item @mouseup="reactLogin" :active="false" v-if="false">
          <template #label>
            <material-icon icon="logout"></material-icon>
            <span class="menu-label-text">Theme configurator</span>
          </template>
        </b-menu-item>
        <b-menu-item :active="isActive('newdashboard')" tag="router-link" to="/admin/newdashboard">
          <template #label>
            <material-icon icon="dashboard"></material-icon>
            <span class="menu-label-text">New dashboard</span>
          </template>
        </b-menu-item>
      <!-- <b-menu-item icon="format-list-checks"
                   label="Feature Settings"
                   :active="isActive('features')"
                   tag="router-link"
                   to="/admin/features"></b-menu-item> -->
      </b-menu-list>
    </b-menu>
    <b-menu class="bottom-menu">
      <b-menu-list>
        <b-menu-item>
          <template #label>
            <material-icon icon="settings"></material-icon>
            <span class="menu-label-text">Admin Home</span>
          </template>
        </b-menu-item>
        <b-menu-item href="https://nibbletechnology.notion.site/Getting-Started-with-Nibble-02a577ea1f384d54a833d9fb4c549cd1" target="_blank">
          <template #label>
            <material-icon icon="assignment"></material-icon>
            <span class="menu-label-text">Documentation &amp; FAQs</span>
          </template>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
    <theme-modal ref="themeModal"></theme-modal>
  </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import nibble from '@/utils/nibble'
import ThemeModal from '@/components/ThemeModal'

export default {
  name: 'AdminMenu',
  components: { MaterialIcon, ThemeModal },
  props: {
    activeItem: {
      required: false,
      type: String
    },
    expanded: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    containerClass () {
      if (this.expanded) {
        return 'expanded'
      } else {
        return 'collapsed'
      }
    }
  },
  methods: {
    isActive (item) {
      return item === this.activeItem
    },
    isExperimentsExpanded () {
      return this.isActive('experiments') || this.isActive('experiments-archive')
    },
    reactLogin () {
      const params = {
        destination: 'app-react'
      }
      nibble.post('/auth/handoff', params, this.$store.getters.authHeaders).then((response) => {
        const url = response.data.url
        this.$refs.themeModal.show(url)
        // window.location.href = url
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
