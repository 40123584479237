<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="block has-text-centered">
              <img src="/images/nibble-logo.png" alt="Nibble. Converse. Convert." width="168" height="50">
            </div>
            <div class="columns">
              <div class="column">
                <table class="table is-narrow is-fullwidth">
                  <tbody v-if="apiVersion">
                    <tr>
                      <th class="has-text-right">API Version</th>
                      <td>{{apiVersion}}</td>
                    </tr>
                    <tr>
                      <th class="has-text-right">Nibble ID</th>
                      <td>{{nibbleId}}</td>
                    </tr>
                    <tr>
                      <th class="has-text-right">Negotiation Type</th>
                      <td>
                        <ValidationProvider :rules="{required: true}" name="Negotiation Type" v-slot="{ errors }">
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select v-model="negotiationType" placeholder="Select a Negotiation Type" expanded>
                              <option value="product">Product (normal sale)</option>
                              <option value="product_buy_back">Product Buy Back</option>
                            </b-select>
                          </b-field>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <tr>
                      <th class="has-text-right">Product Price £</th>
                      <td>
                        <ValidationProvider rules="required" name="product price" v-slot="{ errors }">
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-input v-model="productPrice"></b-input>
                          </b-field>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <tr>
                      <th class="has-text-right">Objective</th>
                      <td>
                        <ValidationProvider :rules="{required: true}" name="Objective" v-slot="{ errors }">
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select v-model="objective" placeholder="Select an Objective" expanded>
                              <option value="increase_conversion">Increase Conversion</option>
                              <option value="balance_performance">Balance Performance</option>
                              <option value="preserve_margin">Preserve Margin</option>
                            </b-select>
                          </b-field>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <tr>
                      <th class="has-text-right">Walkaway Price £</th>
                      <td>
                        <ValidationProvider rules="required" name="walkaway price" v-slot="{ errors }">
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-input v-model="walkawayPrice"></b-input>
                          </b-field>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <tr>
                      <th class="has-text-right">Retailer Session ID</th>
                      <td>{{retailerSessionId}}</td>
                    </tr>
                    <tr>
                      <th class="has-text-right">Language</th>
                      <td>
                        <ValidationProvider :rules="{required: true}" name="Language" v-slot="{ errors }">
                          <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select v-model="language" placeholder="Select a Language" expanded
                              :disabled="selectedFeatures.indexOf('multi_language') === -1">
                              <option v-for="lang in availableLanguages" :key="lang.code" :value="lang.code">
                                {{lang.name}}</option>
                            </b-select>
                          </b-field>
                        </ValidationProvider>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column">
                <table class="table is-narrow is-fullwidth">
                  <tbody v-if="apiVersion">
                    <tr>
                      <th class="has-text-right">Features</th>
                      <td>
                        <b-field v-for="feature in availableFeatures" :key="feature">
                          <b-checkbox :native-value="feature" v-model="selectedFeatures">{{feature}}</b-checkbox>
                        </b-field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column"></div>
            </div>
          </div>
          <p ref="nibble-container" class="nibble" v-if="showNibble">
            <span class="nibble-container">
              <nibble-button id="nibble" show="true" :window-script-url=nibbleWindowUrl :api-key="apiKey"
                :api-version="apiVersion" header-height="100" :product-id="productId" :feature-names="featureNames"
                :language="language"></nibble-button>
            </span>
          </p>
          <div class="basket" v-if="showBasket">
            <notification-bar icon="check">Added to Basket</notification-bar>
            <p class="has-text-centered">
              <b-button type="is-primary is-rounded" @click="recordPurchased">Mark as Purchased</b-button>
            </p>
          </div>
          <div class="purchased" v-if="showPurchased">
            <notification-bar icon="monetization_on">Purchased (order number {{orderNumber}})</notification-bar>
          </div>
          <div class="editor" v-if="sessionData">
            <vue-monaco-editor v-model="sessionData" language="javascript" :options="editorOptions" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import nibble from '@/utils/nibble'
import NotificationBar from '@/components/NotificationBar'

export default {
  name: 'Demo',
  components: { NotificationBar },
  data () {
    return {
      apiVersion: null,
      apiKey: null,
      nibbleId: null,
      negotiationType: 'product',
      productId: null,
      productPrice: null,
      objective: 'increase_conversion',
      walkawayPrice: null,
      nibbleButtonUrl: null,
      nibbleWindowUrl: null,
      widgetLoaded: false,
      retailerSessionId: Math.random().toString(36).substring(2, 15),
      addedToBasket: false,
      purchased: false,
      orderNumber: null,
      language: 'en-GB',
      availableLanguages: [],
      availableFeatures: [],
      selectedFeatures: [],
      sessionData: null
    }
  },
  created () {
    this.fetchData()
  },
  beforeDestroy () {
    if (this.nibbleButtonUrl != null) {
      this.$unloadScript(this.widgetScriptUrl)
    }
  },
  watch: {
    showNibble () {
      this.$nextTick(() => {
        if (this.showNibble) {
          document.getElementById('nibble').addEventListener('nibble-session-start', this.startSession)
          document.getElementById('nibble').addEventListener('nibble-session-end', this.endSession)
        }
      })
    },
    selectedFeatures () {
      if (this.selectedFeatures.indexOf('multi_language') === -1) {
        this.language = 'en-GB'
      }
    }
  },
  computed: {
    showNibble () {
      return this.productId != null && !this.addedToBasket && !this.purchased
    },
    showBasket () {
      return this.addedToBasket && !this.purchased
    },
    showPurchased () {
      return this.purchased
    },
    featureNames () {
      return this.selectedFeatures.join(',')
    },
    editorOptions () {
      return {
        tabSize: 2,
        readOnly: true
      }
    }
  },
  methods: {
    fetchData () {
      nibble.get('/bot_test', {}, {})
        .then((response) => {
          console.log(response)
          this.nibbleButtonUrl = response.data.nibble_button_url
          this.nibbleWindowUrl = response.data.nibble_window_url

          this.$loadScript(this.nibbleButtonUrl)

          this.apiVersion = response.data.api_version
          this.apiKey = response.data.api_key
          this.negotiationType = response.data.negotiation_type
          this.productId = response.data.product_id
          this.productPrice = response.data.product_price
          this.objective = response.data.objective
          this.walkawayPrice = response.data.walkaway_price
          this.availableFeatures = response.data.available_features
          this.availableLanguages = response.data.available_languages
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    startSession (event) {
      const successCallback = event.detail[0]
      const errorCallback = event.detail[1]

      const params = {
        retailerSessionId: this.retailerSessionId,
        negotiationType: this.negotiationType,
        productPrice: this.productPrice,
        objective: this.objective,
        features: [`copy:${this.copyVersion}`].concat(this.selectedFeatures)
      }
      if (this.walkawayPrice != null && this.walkawayPrice !== '') {
        params.walkawayPrice = this.walkawayPrice
      }

      nibble.post('/bot_test', params, this.$store.getters.authHeaders)
        .then(response => {
          this.nibbleId = response.data.nibbleId
          successCallback(response.data)
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
          errorCallback(error)
        })
    },
    endSession (event) {
      const sessionStatus = event.detail[1]
      const endCallback = event.detail[2]
      if (sessionStatus === 'successful') {
        this.addedToBasket = true
      }
      endCallback({ close: true })
      this.fetchSessionData()
    },
    fetchSessionData () {
      nibble.get(`/bot_test/${this.nibbleId}`, {}, this.$store.getters.authHeaders)
        .then(response => {
          this.sessionData = JSON.stringify(response.data, null, 2)
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    recordPurchased (event) {
      this.orderNumber = Math.random().toString(10).substring(2, 7)
      const params = {
        productId: this.productId,
        nibbleId: this.nibbleId,
        orderNumber: this.orderNumber
      }

      nibble.post('/bot_test/purchase', params, this.$store.getters.authHeaders)
        .then(response => {
          this.purchased = true
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.nibble-container {
  display: block;
  max-width: 400px;
  margin: 0 auto 0;
  padding-bottom: 2em;
}
.editor {
  margin-top: 2em;
  height: 500px;
}
</style>
