<template>
  <page-layout :retailer-id="navRetailerId" active-menu-item="dashboard">
    <main-column :breadcrumbs="breadcrumbs" :page-title="breadcrumbs[0].title" content-class="main-column-sub-columns columns">
      <session-filters :options="filterOptions" :filters="filters" @change="updateFilters" v-if="filterOptions"/>
      <div class="column">
        <div class="main-column-content">
          <div class="columns" v-if="abtestStats">
            <div class="column">
              <card-pre-header title="Uplift A/B Test" :subtitle="abtestSubtitle" icon="call_split"/>
              <div class="card">
                <div class="card-content uplift">
                  <div class="columns">
                    <div class="column is-flex is-flex-direction-column is-align-items-flex-start" v-if="abtestStats.uplift.conversion">
                      <uplift-stat title="Conversion Uplift" icon="monetization_on" :data="abtestStats.uplift.conversion" :show-change="canSeeLimitedData"/>
                    </div>
                    <div class="column is-flex is-flex-direction-column is-align-items-flex-start" v-if="abtestStats.uplift.aov">
                      <uplift-stat title="Avg. Order Value Uplift" icon="request_quote" :data="abtestStats.uplift.aov" :show-change="canSeeLimitedData"/>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-flex is-flex-direction-column is-align-items-flex-start" v-if="abtestStats.uplift.revenue">
                      <uplift-stat title="Predicted Revenue Uplift" icon="local_atm" :data="abtestStats.uplift.revenue" :show-change="canSeeLimitedData"/>
                    </div>
                    <div class="column is-flex is-flex-direction-column is-align-items-flex-start" v-if="abtestStats.uplift.revenue_relative">
                      <uplift-stat title="Predicted Revenue Change" icon="local_atm" :data="abtestStats.uplift.revenue_relative" :show-change="canSeeLimitedData"/>
                    </div>
                  </div>
                  <conversion-significance
                    :control-sales="abtestStats.control.sales"
                    :control-sessions="abtestStats.control.sessions"
                    :test-sales="abtestStats.test.sales"
                    :test-sessions="abtestStats.test.sessions"/>
                </div>
              </div>
            </div>
          </div>
          <div class="columns same-height">
            <div class="column">
              <card-pre-header title="Overview" :subtitle="subtitle" icon="language"/>
              <div class="card">
                <div class="card-content">
                  <div v-if="toplineStats" class="is-flex is-flex-direction-column is-align-items-flex-start">
                    <!-- <stat title="Clickthrough Rate" icon="touch_app" status="added-to-basket" :data="clickThroughStats.click_through_rate" v-if="isAdmin() && clickThroughStats"/> -->
                    <stat v-if="canSeeLimitedData" title="Nibble Sessions" icon="question_answer" :data="toplineStats.total_sessions" :bold="true"/>
                    <stat title="Deals Agreed" icon="thumb_up" :data="negotiationFlowStats.total_agreed_deal" :show-change="canSeeLimitedData"/>
                    <stat title="Purchased" icon="monetization_on" status="sold" :data="toplineStats.total_purchased" :show-change="canSeeLimitedData"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <card-pre-header title="Performance" subtitle="How is your discount strategy performing?" icon="attach_money"/>
              <div class="card">
                <div class="card-content">
                  <div v-if="performanceStats" class="is-flex is-flex-direction-column is-align-items-flex-start">
                    <stat title="Total Revenue" icon="local_atm" :data="performanceStats.total_revenue" :bold="true" :show-change="canSeeLimitedData"/>
                    <stat title="Avg. Nibbled Value" icon="request_quote" :data="performanceStats.avg_nibble_value" :show-change="canSeeLimitedData"/>
                    <stat title="Avg. Aggregated Discount" icon="sell" :data="performanceStats.avg_agg_discount" :reverse="true" :show-change="canSeeLimitedData" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns same-height">
            <div class="column">
              <card-pre-header title="Rules &amp; Objectives" subtitle="How is Nibble helping with your objectives?" icon="book"/>
              <div class="columns">
                <div class="column">
                  <div class="card">
                    <div class="card-content">
                      <h4 class="title is-4 mb-4">Active Rules</h4>
                      <active-rule-list :active-rules="activeRules" :max-rules="3" :retailer-id="retailerId"/>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="card">
                    <div class="card-content">
                      <h4 class="title is-4 mb-4">Recovered Nibbles</h4>
                      <div v-if="viewAsAdmin || (upgrades.recoveredNibbles.unlocked && upgrades.recoveredNibbles.active)"
                        class="is-flex is-flex-direction-column is-align-items-flex-start">
                        <stat v-if="recoveredNibbleStats"
                          title="Waiting for Review"
                          icon="status_user_offer_available" :svg="true" status="offer-pending"
                          :data="recoveredNibbleStats.waiting_for_review"/>
                        <stat v-if="recoveredNibbleStats" class="mb-3"
                          title="Avg. Accepted Discount"
                          icon="status_user_offer_accepted" :svg="true"
                          :data="recoveredNibbleStats.avg_accepted_discount" :reverse="true"/>
                        <b-button v-if="recoveredNibbleStats && recoveredNibblesBrowseLink" :to="recoveredNibblesBrowseLink"
                          type="is-primary is-rounded" tag="router-link">Review Offers</b-button>
                      </div>
                      <div v-else-if="upgrades.recoveredNibbles.unlocked && !upgrades.recoveredNibbles.active"
                        class="is-flex is-flex-direction-column is-align-items-flex-start">
                        <b-message type="is-rounded">
                          None of your Rules have Recovered Nibbles active at the moment; edit a Rule to add it.
                        </b-message>
                        <b-button v-if="recoveredNibbleStats && recoveredNibblesEditLink"
                          type="is-primary is-rounded" tag="router-link" :to="recoveredNibblesEditLink">Edit Rules</b-button>
                      </div>
                      <div v-else
                        class="is-flex is-flex-direction-column is-align-items-flex-start">
                        <stat v-if="recoveredNibbleStats"
                          title="Waiting for Review"
                          icon="status_user_offer_available" :svg="true" status="offer-pending" :data="blankStat"/>
                        <stat v-if="recoveredNibbleStats" class="mb-3"
                          title="Avg. Accepted Discount" icon="status_user_offer_accepted" :svg="true"
                          :data="blankStat" :reverse="true" :show-change="canSeeLimitedData"/>
                        <b-button v-if="recoveredNibbleStats && billingLink" :to="billingLink"
                          type="is-rounded" icon-right="lock" tag="router-link">Add Recovered Nibbles</b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="card">
                    <div class="card-content">
                      <h4 class="title is-4 mb-4">Bundling &amp; Addons</h4>
                      <div v-if="viewAsAdmin || (upgrades.addons.unlocked && upgrades.addons.active)"
                        class="is-flex is-flex-direction-column is-align-items-flex-start">
                        <stat v-if="addonStats"
                          title="Purchases w/Addons"
                          icon="status_purchased_with_addon" :svg="true" status="sold"
                          :data="addonStats.purchases_with_addons"/>
                        <stat v-if="addonStats" class="mb-3"
                          title="AOV w/Addons"
                          icon="addon" :svg="true"
                          :data="addonStats.aov_with_addons"/>
                        <b-button v-if="addonStats && addonsBrowseLink" :to="addonsBrowseLink"
                          type="is-primary is-rounded" tag="router-link">See All</b-button>
                      </div>
                      <div v-else-if="upgrades.addons.unlocked && !upgrades.addons.active"
                        class="is-flex is-flex-direction-column is-align-items-flex-start">
                        <b-message type="is-rounded">
                          None of your Rules have Addons active at the moment; edit a Rule to add it.
                        </b-message>
                        <b-button v-if="addonStats && addonsEditLink" :to="addonsEditLink"
                          type="is-primary is-rounded" tag="router-link">Edit Rules</b-button>
                      </div>
                      <div v-else
                        class="is-flex is-flex-direction-column is-align-items-flex-start">
                        <stat v-if="addonStats"
                          title="Purchases w/Addons"
                          icon="status_purchased_with_addon" :svg="true" status="sold"
                          :data="blankStat"/>
                        <stat v-if="addonStats" class="mb-3"
                          title="AOV w/Addons"
                          icon="addon" :svg="true"
                          :data="blankStat"/>
                        <b-button v-if="addonStats && billingLink" :to="billingLink"
                          type="is-rounded" icon-right="lock" tag="router-link">Add Bundling &amp; Addons</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <card-pre-header v-if="canSeeLimitedData || viewAsAdmin" title="Negotiation Flows" subtitle="How far did your users get through the negotiation?" icon="call_split"/>
              <div v-if="canSeeLimitedData || viewAsAdmin" class="card mb-6">
                <div class="card-content">
                  <b-tabs v-if="negotiationFlowStats" v-model="negotiationFlowsTab" expanded>
                    <b-tab-item label="All">
                      <table class="table is-fullwidth stats-table">
                        <tbody>
                          <stat-row title="Nibble Sessions" icon="question_answer" :show-change="false"
                            :stat="negotiationFlowStats.total_sessions"/>
                          <stat-row title="Negotiated" icon="chat" :show-change="timeRange"
                            :stat="negotiationFlowStats.total_negotiated" :percentage="negotiationFlowStats.total_negotiated_percentage"/>
                          <stat-row title="Got a Final Offer" icon="pan_tool" :show-change="timeRange"
                            :stat="negotiationFlowStats.total_final_offer" :percentage="negotiationFlowStats.total_final_offer_percentage"/>
                          <stat-row title="Agreed a Deal" icon="thumb_up" :show-change="timeRange"
                            :stat="negotiationFlowStats.total_agreed_deal" :percentage="negotiationFlowStats.total_agreed_deal_percentage"/>
                          <stat-row title="Added to Basket (PDP)" icon="add_shopping_cart" :show-change="timeRange"
                            :stat="negotiationFlowStats.total_added_to_basket" :percentage="negotiationFlowStats.total_added_to_basket_percentage"/>
                          <stat-row title="Purchased" icon="monetization_on" :show-change="timeRange"
                            :stat="negotiationFlowStats.total_purchased" :percentage="negotiationFlowStats.total_purchased_percentage"/>
                          <stat-row title="Purchased with Addon" icon="addon" :svg="true" :show-change="timeRange"
                            :stat="negotiationFlowStats.total_purchased_with_addon" :percentage="negotiationFlowStats.total_purchased_with_addon_percentage"/>
                          <tr><th colspan="4" class="pt-5">Details</th></tr>
                          <stat-row title="Negotiations on PDP" icon="sell" :show-change="timeRange"
                            :stat="negotiationFlowStats.pdp_sessions" :percentage="negotiationFlowStats.pdp_sessions_percentage"/>
                          <stat-row title="Negotiations on Cart" icon="shopping_cart" :show-change="timeRange"
                            :stat="negotiationFlowStats.cart_sessions" :percentage="negotiationFlowStats.cart_sessions_percentage"/>
                        </tbody>
                      </table>
                    </b-tab-item>
                    <b-tab-item label="PDP">
                      <table class="table is-fullwidth stats-table">
                        <tbody>
                          <stat-row title="Nibble Sessions" icon="question_answer" :show-change="false"
                            :stat="negotiationFlowStats.pdp_sessions"/>
                          <stat-row title="Negotiated" icon="chat" :show-change="timeRange"
                            :stat="negotiationFlowStats.pdp_negotiated" :percentage="negotiationFlowStats.pdp_negotiated_percentage"/>
                          <stat-row title="Got a Final Offer" icon="pan_tool" :show-change="timeRange"
                            :stat="negotiationFlowStats.pdp_final_offer" :percentage="negotiationFlowStats.pdp_final_offer_percentage"/>
                          <stat-row title="Agreed a Deal" icon="thumb_up" :show-change="timeRange"
                            :stat="negotiationFlowStats.pdp_agreed_deal" :percentage="negotiationFlowStats.pdp_agreed_deal_percentage"/>
                          <stat-row title="Added to Basket" icon="add_shopping_cart" :show-change="timeRange"
                            :stat="negotiationFlowStats.pdp_added_to_basket" :percentage="negotiationFlowStats.pdp_added_to_basket_percentage"/>
                          <stat-row title="Purchased" icon="monetization_on" :show-change="timeRange"
                            :stat="negotiationFlowStats.pdp_purchased" :percentage="negotiationFlowStats.pdp_purchased_percentage"/>
                          <stat-row title="Purchased with Addon" icon="addon" :svg="true" :show-change="timeRange"
                            :stat="negotiationFlowStats.pdp_purchased_with_addon" :percentage="negotiationFlowStats.pdp_purchased_with_addon_percentage"/>
                        </tbody>
                      </table>
                    </b-tab-item>
                    <b-tab-item label="Cart">
                      <table class="table is-fullwidth stats-table">
                        <tbody>
                          <stat-row title="Nibble Sessions" icon="question_answer" :show-change="false"
                            :stat="negotiationFlowStats.cart_sessions"/>
                          <stat-row title="Negotiated" icon="chat" :show-change="timeRange"
                            :stat="negotiationFlowStats.cart_negotiated" :percentage="negotiationFlowStats.cart_negotiated_percentage"/>
                          <stat-row title="Got a Final Offer" icon="pan_tool" :show-change="timeRange"
                            :stat="negotiationFlowStats.cart_final_offer" :percentage="negotiationFlowStats.cart_final_offer_percentage"/>
                          <stat-row title="Agreed a Deal" icon="thumb_up" :show-change="timeRange"
                            :stat="negotiationFlowStats.cart_agreed_deal" :percentage="negotiationFlowStats.cart_agreed_deal_percentage"/>
                          <stat-row title="Purchased" icon="monetization_on" :show-change="timeRange"
                            :stat="negotiationFlowStats.cart_purchased" :percentage="negotiationFlowStats.cart_purchased_percentage"/>
                          <stat-row title="Purchased with Addon" icon="addon" :svg="true" :show-change="timeRange"
                            :stat="negotiationFlowStats.cart_purchased_with_addon" :percentage="negotiationFlowStats.cart_purchased_with_addon_percentage"/>
                        </tbody>
                      </table>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="column">
              <card-pre-header v-if="canSeeLimitedData || viewAsAdmin " title="Discount Flows" subtitle="What discounts are users achieving throughout the negotiation?" icon="discount"/>
              <div v-if="canSeeLimitedData || viewAsAdmin" class="card mb-5">
                <div class="card-content">
                  <table v-if="discountFlowStats" class="table is-fullwidth stats-table">
                    <tbody>
                      <tr><th colspan="4" class="has-text-right pt-5">Average Target Discount</th></tr>
                      <stat-row title="All Sessions" icon="question_answer" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.total_sessions" :percentage="discountFlowStats.total_avg_target_discount"/>

                      <tr><th colspan="4" class="has-text-right pt-5">Average Walkaway Discount</th></tr>
                      <stat-row title="All Sessions" icon="question_answer" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.total_sessions" :percentage="discountFlowStats.total_avg_walkaway_discount"/>

                      <tr><th colspan="4" class="has-text-right pt-5">Average Nibble Offered Discount</th></tr>
                      <stat-row title="All Sessions" icon="question_answer" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.total_sessions" :percentage="discountFlowStats.total_avg_offered_discount"/>
                      <stat-row title="Abandoned" icon="logout" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.abandoned_sessions" :percentage="discountFlowStats.abandoned_avg_offered_discount"/>
                      <stat-row title="Agreed a Deal" icon="thumb_up" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.agreed_sessions" :percentage="discountFlowStats.agreed_avg_offered_discount"/>
                      <stat-row title="Added to Basket" icon="add_shopping_cart" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.added_to_basket_sessions" :percentage="discountFlowStats.added_to_basket_avg_offered_discount"/>
                      <stat-row title="Purchased" icon="monetization_on" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.purchased_sessions" :percentage="discountFlowStats.purchased_avg_offered_discount"/>

                      <tr><th colspan="4" class="has-text-right pt-5">Average Last User Offer (Discount)</th></tr>
                      <stat-row title="Sessions with User Offer" icon="question_answer" :show-change="timeRange" :reverse="true"
                        :stat="discountFlowStats.total_user_offer_sessions" :percentage="discountFlowStats.total_avg_user_last_offer_discount"/>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="viewAsAdmin" class="card mb-5">
                <div class="card-content">
                  <h4 class="title is-4 mb-4">Walkaway Hits</h4>
                  <div v-if="discountFlowStats" class="is-flex is-flex-direction-column is-align-items-flex-start">
                    <stat title="Number of Walkaway Hits" icon="directions_walk" :data="discountFlowStats.walkaway_hits" :reverse="true"/>
                  </div>
                </div>
              </div>

              <card-pre-header v-if="canSeeLimitedData || viewAsAdmin" title="Engagement" subtitle="How did your users engage with Nibble?" icon="mood"/>
              <div v-if="canSeeLimitedData || viewAsAdmin" class="card mb-5">
                <div class="card-content">
                  <h4 class="title is-4 mb-4">Failed Negotiations Feedback</h4>
                  <div v-if="feedbackStats" class="is-flex is-flex-direction-column is-align-items-flex-start">
                    <stat title="Positive" icon="thumb_up" :data="feedbackStats.positive_ratings"/>
                    <stat title="Negative" icon="thumb_down" :data="feedbackStats.negative_ratings" :reverse="true"/>
                  </div>
                </div>
              </div>
              <div v-if="canSeeLimitedData || viewAsAdmin" class="card">
                <div class="card-content">
                  <h4 class="title is-4 mb-4">Details</h4>
                  <table v-if="engagementStats" class="table is-fullwidth stats-table">
                    <tbody>
                      <stat-row title="Avg. Session Duration" icon="schedule" :show-change="timeRange" :stat="engagementStats.avg_session_duration"/>
                      <stat-row title="Avg. No. of Reopened Sessions" icon="repeat" :show-change="timeRange" :stat="engagementStats.avg_reopened_sessions"/>
                      <stat-row title="Avg. Chattiness" icon="campaign" :show-change="timeRange" :stat="engagementStats.avg_chattiness"/>
                    </tbody>
                  </table>
                  <zone-grid :zoneWalks="walks" v-if="viewAsAdmin && walks" :showHeat="true" :labels="true" :width="200" :height="200"/>
                </div>
              </div>
            </div>
          </div>

          <sankey-chart id="chart" :chartData="sankeyData" v-if="viewAsAdmin && sankeyData"></sankey-chart>

        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import nibble from '@/utils/nibble'
import moment from 'moment'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import SessionFilters from '@/components/SessionFilters'
import SankeyChart from '@/components/SankeyChart'
import ZoneGrid from '@/components/ZoneGrid'
import UpliftStat from '@/components/analytics/UpliftStat'
import Stat from '@/components/analytics/Stat'
import StatRow from '@/components/analytics/StatRow'
import ActiveRuleList from '@/components/analytics/ActiveRuleList'
import CardPreHeader from '@/components/CardPreHeader'
import ConversionSignificance from '@/components/analytics/ConversionSignificance'

export default {
  name: 'AdminDashboard',
  components: { PageLayout, MainColumn, SessionFilters, SankeyChart, ZoneGrid, CardPreHeader, Stat, StatRow, ActiveRuleList, UpliftStat, ConversionSignificance },
  data () {
    return {
      filterOptions: null,
      filters: {},
      loading: true,
      toplineStats: null,
      performanceStats: null,
      recoveredNibbleStats: null,
      addonStats: null,
      negotiationFlowStats: null,
      negotiationFlowsTab: 0,
      discountFlowStats: null,
      engagementStats: null,
      // engagementLabels: ['high', 'neutral', 'low', 'pending', 'unavailable'],
      feedbackStats: null,
      walksError: null,
      walks: null,
      sankeyData: null,
      activeRules: null,
      abtestStats: null,
      upgrades: {
        recoveredNibbles: {
          unlocked: false,
          active: false
        },
        addons: {
          unlocked: false,
          active: false
        }
      }
    }
  },
  computed: {
    navRetailerId () {
      // Fetches :retailerId if the route matches /retailers/:retailerId/dashboard
      return this.$route.params.retailerId ? parseInt(this.$route.params.retailerId) : null
    },
    queryRetailerId () {
      // Returns the retailer ID if it is specified as a query parameter
      return this.$route.query.retailerId ? parseInt(this.$route.query.retailerId) : null
    },
    retailerId () {
      return this.viewAsAdmin ? this.queryRetailerId : this.navRetailerId
    },
    viewAsAdmin () {
      if (this.isAdmin()) {
        return this.navRetailerId == null
      } else {
        return false
      }
    },
    defaultDataQuality () {
      return this.viewAsAdmin ? '3' : null
    },
    recoveredNibblesBrowseLink () {
      var query = this.$route.query

      if (this.retailerId != null) {
        query.retailerId = this.retailerId
      }

      query.summaryStatus = 'user_offer_available'

      if (this.viewAsAdmin) {
        return `/sessions?${this.queryString(query)}`
      } else {
        delete query.retailerId
        return this.retailerLink(`/sessions?${this.queryString(query)}`)
      }
    },
    addonsBrowseLink () { return this.retailerLink('/addon_items') },
    recoveredNibblesEditLink () { return this.retailerLink('/rules') },
    addonsEditLink () { return this.retailerLink('/rules') },
    billingLink () { return this.retailerLink('/account/plan/choice') },
    breadcrumbs () {
      return [
        {
          link: '/admin/dashboard',
          title: this.viewAsAdmin ? 'Admin Dashboard' : 'Dashboard'
        }
      ]
    },
    subtitle () {
      return 'A quick summary of Nibble activity'
    },
    timeRange: function () {
      if ('startDate' in this.filters) {
        return this.filters.startDate != null
      } else if ('endDate' in this.filters) {
        return this.filters.endDate != null
      } else {
        return false
      }
    },
    blankStat: function () {
      return {
        value: '-'
      }
    },
    canSeeLimitedData () {
      return !this.$store.getters.isLimitedData
    },
    abtestSubtitle () {
      return `Test started ${moment(this.abtestStats.startDate).format('DD/MM/YYYY HH:mm')} UTC. ` +
        "Note: filters don't affect test results. Predicted revenue is extrapolated from test results."
    }
  },
  created () {
    this.fetchOptions()
  },
  mounted () {
    this.updateAndFetch()
  },
  watch: {
    $route: 'updateAndFetch'
  },
  methods: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    queryString (params) {
      return Object.keys(params).map(key => key + '=' + params[key]).join('&')
    },
    defaultDateRange () {
      var now = moment()
      var endMoment = now.clone()
      var startMoment = now.clone().subtract(6, 'days')
      return [startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD')]
    },
    updateAndFetch () {
      var defaultDateRange = this.defaultDateRange()
      this.offset = this.$route.query.offset != null ? parseInt(this.$route.query.offset) : 0
      this.filters = {
        dataQuality: this.$route.query.dataQuality != null ? this.$route.query.dataQuality : this.defaultDataQuality,
        chattinessCategory: this.$route.query.chattinessCategory != null ? this.$route.query.chattinessCategory : null,
        retailerId: this.$route.query.retailerId != null ? parseInt(this.$route.query.retailerId) : null,
        summaryStatus: this.$route.query.summaryStatus != null ? this.$route.query.summaryStatus : null,
        timeRange: this.$route.query.timeRange != null ? this.$route.query.timeRange : '7d',
        // Route contains start and end dates as UTC, I'm converting to user timezone
        // startDate: this.$route.query.startDate != null ? this.$route.query.startDate : null,
        // endDate: this.$route.query.endDate != null ? this.$route.query.endDate : null,
        startDate: this.$route.query ? this.$route.query.startDate != null ? this.$route.query.startDate : null : null,
        endDate: this.$route.query ? this.$route.query.endDate != null ? this.$route.query.endDate : null : null,
        zeroLength: this.$route.query.zeroLength === 'true',
        hasGenerativeAtoms: this.$route.query.hasGenerativeAtoms === 'true',
        hasGenerativeComposer: this.$route.query.hasGenerativeComposer === 'true',
        incorrectIntentInference: this.$route.query.incorrectIntentInference === 'true',
        incorrectEntityInference: this.$route.query.incorrectEntityInference === 'true',
        incorrectGptOutput: this.$route.query.incorrectGptOutput === 'true',
        objective: this.$route.query.objective,
        cohort: this.$route.query.cohort,
        experiment: this.$route.query.experiment,
        rule: this.$route.query.rule
      }

      if ((this.filters.timeRange === '7d') && !this.filters.startDate && !this.filters.endDate) {
        this.filters.startDate = defaultDateRange[0]
        this.filters.endDate = defaultDateRange[1]
      }

      this.sankeyData = null
      this.walks = null
      this.toplineStats = null
      this.performanceStats = null
      this.recoveredNibbleStats = null
      this.addonStats = null
      this.negotiationFlowStats = null
      this.discountFlowStats = null
      this.feedbackStats = null
      this.engagementStats = null
      this.activeRules = null
      this.abtestStats = null
      this.fetchStats()
    },
    fetchOptions () {
      console.log('Load Stats Options')
      this.loading = true

      var params = { action: 'options' }
      if (this.navRetailerId != null) {
        params.retailerId = parseInt(this.navRetailerId)
      }

      nibble.post('/stats', params, this.$store.getters.authHeaders)
        .then((response) => {
          console.log(response)
          this.filterOptions = response.data
          this.loading = false
        }).catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchStats () {
      var sections = ['topline', 'performance', 'recoveredNibble', 'addon', 'negotiationFlow', 'discountFlow', 'feedback', 'engagement']

      if (this.viewAsAdmin) {
        // Load click-through stats after performance stats
        sections.splice(2, 0, 'clickThrough')
      }
      if (this.isAdmin && this.navRetailerId != null) {
        sections.push('abtest')
      }

      var fetchNext = null
      fetchNext = () => {
        if (sections.length > 0) {
          const sectionName = sections.pop()
          this.fetchSectionStats(sectionName)
            .then(fetchNext)
        } else {
          this.fetchWalks()
        }
      }
      fetchNext()
    },
    fetchSectionStats (sectionName) {
      console.log(`Fetch Stats ${sectionName}`)

      var query = this.filterQuery(this.filters)
      query.action = sectionName !== 'abtest' ? 'stats' : 'abtest'
      query.section = sectionName
      query.navRetailerId = this.navRetailerId

      return nibble.post('/stats', query, this.$store.getters.authHeaders)
        .then((response) => {
          this[sectionName + 'Stats'] = response.data
        }).catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchWalks () {
      console.log('Load Walks')

      var query = this.filterQuery(this.filters)
      query.action = 'walks'

      nibble.post('/stats', query, this.$store.getters.authHeaders)
        .then((response) => { this.walks = response.data.walks })
        .catch((error) => {
          if (error.response != null && error.response.data != null) {
            this.walksError = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        }).finally(() => { this.fetchSankey() })
    },
    fetchSankey () {
      console.log('Load Sankey Data')

      var query = this.filterQuery(this.filters)
      query.action = 'sankey'

      nibble.post('/stats', query, this.$store.getters.authHeaders)
        .then((response) => {
          this.sankeyData = response.data
          this.sankeyData.iterations = 2
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
        .finally(() => { this.fetchBillingDetails() })
    },
    fetchBillingDetails () {
      if (this.retailerId) {
        console.log('Load Billing Details')

        nibble.get(this.retailerLink('/account/plan'), { retailer_id: this.retailerId, context: 'retailer' }, this.$store.getters.authHeaders)
          .then((response) => {
            var features = response.data.pricing_plan.feature_names
            this.upgrades.recoveredNibbles.unlocked = features.includes('abandoned_nibbles')
            this.upgrades.addons.unlocked = features.includes('addons')
          })
          .catch((error) => {
            nibble.handleNetworkError(error, this.$store)
          })
          .finally(() => { this.fetchActiveRules() })
      }
    },
    fetchActiveRules () {
      console.log('Load Active Rule Data')

      nibble.get('/rules', { context: 'ruleset', retailer_id: this.retailerId }, this.$store.getters.authHeaders)
        .then((response) => {
          this.activeRules = response.data.rules.filter(rule => rule.active)
          for (const entry of Object.entries(this.activeRules)) {
            var rule = entry[1]
            this.upgrades.recoveredNibbles.active = this.upgrades.recoveredNibbles.active || rule.recovered_nibbles_active
            this.upgrades.addons.active = this.upgrades.addons.active || rule.rule_addon_items.length > 0
          }
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    updateFilters (newFilters) {
      console.log('update filters')
      var query = this.filterQuery(newFilters)
      return this.$router.push({
        path: `/${this.viewAsAdmin ? 'admin' : `retailers/${query.retailerId}`}/dashboard`,
        query: query
      })
    },
    filterQuery (filters) {
      // console.log([filters.startDate, filters.endDate])
      var query = { }
      if (filters.dataQuality != null) {
        query.dataQuality = filters.dataQuality
      }
      if (filters.chattinessCategory != null) {
        query.chattinessCategory = filters.chattinessCategory
      }
      if (filters.retailerId != null) {
        query.retailerId = filters.retailerId
      } else {
        if (this.$route.params.retailerId != null) {
          query.retailerId = parseInt(this.$route.params.retailerId)
        }
      }
      if (filters.summaryStatus != null) {
        query.summaryStatus = filters.summaryStatus
      }
      if (filters.timeRange != null) {
        query.timeRange = filters.timeRange
      }
      if (filters.startDate != null) {
        query.startDate = filters.startDate // RETAILERS's midnight in UTC
      }
      if (filters.endDate != null) {
        query.endDate = filters.endDate // RETAILERS's  end day in UTC
      }
      if (filters.zeroLength) {
        query.zeroLength = 'true'
      }
      if (filters.hasGenerativeAtoms) {
        query.hasGenerativeAtoms = 'true'
      }
      if (filters.hasGenerativeComposer) {
        query.hasGenerativeComposer = 'true'
      }
      if (filters.incorrectIntentInference) {
        query.incorrectIntentInference = 'true'
      }
      if (filters.incorrectEntityInference) {
        query.incorrectEntityInference = 'true'
      }
      if (filters.incorrectGptOutput) {
        query.incorrectGptOutput = 'true'
      }
      if (filters.objective != null) {
        query.objective = filters.objective
      }
      if (filters.cohort != null) {
        query.cohort = filters.cohort
      }
      if (filters.experiment != null) {
        query.experiment = filters.experiment
      }
      if (filters.rule != null) {
        query.rule = filters.rule
      }
      // console.log('QUERY', query, filters)

      return query
    },
    retailerLink (path) {
      return this.retailerId ? `/retailers/${this.retailerId}${path}` : null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
table.stats tbody tr:hover {
  cursor: pointer;
}
.no-border-bottom {
  border-bottom: none !important;
}
.card-content {
  overflow-x: auto;
}
.card-content.uplift{
  // No idea at all, weird CSS behaviour
  overflow-x: hidden;
}
@media screen and (min-width: 769px), print {
  .columns.same-height:not(.is-desktop) {
    .column {
      display: flex;
      flex-direction: column;
      .card {
        flex: 1;
      }
    }
  }
}

/*
#engagement-title {
  position: relative;
  span {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin-left: 10px;
    padding: 3px 5px;
    font-size: 0.75rem;
    color: white;
    background: #A3B3C2; // $grey-l70 from app.scss
    border-radius: 4px;
  }
}

#engagement-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 5px 15px;
  box-sizing: border-box;
  #engagement-chart-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    #engagement-chart {
      z-index: 1;
      width: 95%;
      min-width: 120px;
      max-width: 200px;
    }
    #engagement-score-wrapper {
      z-index: 0;
      // Position score in middle of doughnut
      position: absolute;
      bottom: 50%;
      -webkit-transform: translate(0, 50%);
      transform: translate(0, 50%);
      #engagement-score {
        font-size: 1.5rem;
        font-weight: 500;
        color: #1F262E; // $grey-l15 from app.scss
        span {
          font-size: 1.1rem;
          font-weight: normal;
        }
      }
      #engagement-category {
        font-size: 0.9rem;
      }
    }
  }
  #engagement-expression-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    img {
      width: 60%;
      min-width: 65px;
      max-width: 105px;
    }
  }
} */
</style>
